import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const ActionMenu = ({
  row,
  handleOpenDescriptionModal,
  handleOpenStatusModal,
  handleOpenInputFileModal,
  handleOpenReviewModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const parseAvailableActions = (actions) => {
    try {
      return JSON.parse(actions);
    } catch (e1) {
      try {
        const cleanedActions = actions.replace(/\\/g, "");
        return JSON.parse(cleanedActions);
      } catch (e2) {
        console.error("Falha ao parsear available_actions:", e2);
        return [];
      }
    }
  };
  
  let availableActions = [];
  if (typeof row?.claim?.available_actions === "string") {
    try {
      availableActions = JSON.parse(row.claim.available_actions);
    } catch (e) {
      console.error("Failed to parse available_actions:", e);
    }
  } else if (Array.isArray(row?.claim?.available_actions)) {
    availableActions = row.claim.available_actions;
  }

  const hasReviewActions = availableActions.some(
    (action) =>
      action.action === "return_review_ok" ||
      action.action === "return_review_fail"
  );

  return (
    <>
      <Tooltip title="Ações">
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenDescriptionModal(row);
            handleClose();
          }}
        >
          {row.description ? "Editar descrição" : "Adicionar descrição"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenStatusModal(row);
            handleClose();
          }}
        >
          Editar status Weello
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenInputFileModal(row);
            handleClose();
          }}
        >
          Inserir anexo
        </MenuItem>
        {row.statusMP === "delivered" &&
          hasReviewActions &&
          (!row.review_status ||
            row.review_status === null ||
            row.review_status === "null") && (
            <MenuItem
              onClick={() => {
                handleOpenReviewModal(row);
                handleClose();
              }}
            >
              Revisar
            </MenuItem>
          )}
      </Menu>
    </>
  );
};

export default ActionMenu;
