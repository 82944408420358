import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Divider,
  Avatar,
  AvatarGroup,
  Tooltip,
  avatarClasses,
  Stack,
} from "@mui/material";
import { ExpandLess, ExpandMore, InfoOutlined, AttachFile as AttachFileIcon } from "@mui/icons-material";
import ActionMenu from "src/components/action-menu";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { failReasonStatus, formatCurrency, formatDate, getStatus } from "src/utils/utils-returns";
import { AnnouncementItemShippingInfo } from "src/sections/dashboard/chat/AnnouncementItem/AnnouncementItemShippingInfo";
import styled from "styled-components";
import ImageAvatar from "./avatar-row";
import { useNavigate } from "react-router";
import { useChatKey } from "src/contexts/chat-key-context";

export const TitleWrapper = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export const SkuWrapper = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;


const ReturnRow = ({
  row,
  index,
  expandedRow,
  handleExpandClick,
  attachmentsExist,
  handleOpenAttachmentListModal,
  handleOpenDetailDrawer,
  handleOpenDescriptionModal,
  handleOpenStatusModal,
  handleOpenInputFileModal,
  handleOpenReviewModal,
  hasFulfillment,
}) => {
  const navigate = useNavigate();
  const { chatKey, setChatKey } = useChatKey();
  const handleReturnToClaim = () => {
    setChatKey(row.conversation.id);
    navigate(`/v1/claims?key=${row.conversation.id}`);
  };
  console.log(row);
  
  return (
    <>
      <TableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick(index)}
          >
            {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
       </TableCell>
        <TableCell sx={{ width: "310px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              variant="rounded"
              style={{
                height: "40px",
                width: "40px",
                border: "1px solid #ddd",
              }}
            >
              <img
                src={row.thumbnail}
                alt="Thumbnail"
                style={{ height: "40px" }}
              />
            </Avatar>
            <div>
              <TitleWrapper>{row.title}</TitleWrapper>
            </div>
          </Box>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Stack>
            <Stack direction="row" alignItems="center">
              <strong>Marketplace:&nbsp;</strong>
              <span>{getStatus(row.statusMP)}</span>
             </Stack>
            <Stack direction="row">
              <strong>Weello:&nbsp;</strong>
              <span>{getStatus(row.statusWeello)}</span>
            </Stack>
            <Stack direction="row">
              <strong>Revisão:&nbsp;</strong>
              <span>
                {row.review_status === "success"
                  ? `Revisado com sucesso`
                  : (row.review_status === "claimed" ||
                      row.review_status === "failed" ||
                      row.review_status === "success") &&
                    row.user_name === null
                  ? "Revisão de devolução enviada pelo marketplace."
                  : row.review_status === "claimed" || row.review_status === "failed"
                  ? `${failReasonStatus(row?.fail_reason)}`
                  : "Ainda não revisado"}
              </span>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{row.codPedido}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatDate(row.createdAt)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatCurrency(row.total_amount)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {row.quantity} unidade
        </TableCell>
        <TableCell sx={{ width: "210px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <SkuWrapper>{row.announcement?.sku}</SkuWrapper>
            </Box>
          </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{row.customer}</TableCell>
        <Tooltip title={row.account} arrow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <AvatarGroup
              max={2}
              sx={{
                [`& .${avatarClasses.root}`]: {
                  height: 40,
                  width: 40,
                },
              }}
            >
              <ImageAvatar row={row} />
              </AvatarGroup>
            </Box>
        </TableCell>
        </Tooltip>
        {hasFulfillment && (
          <TableCell sx={{ whiteSpace: "nowrap" }}>
            <AnnouncementItemShippingInfo
              freeShipping={null}
              logisticType={row.announcement?.logistic_type}
            />
          </TableCell>
        )} 
        <TableCell align="center">
        <Stack direction="row">
       <Tooltip title="Retornar para reclamacao">
            <IconButton onClick={handleReturnToClaim}>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Tooltip>
          {attachmentsExist[row.id] && (
            <Tooltip title="Exibir anexos">
              <IconButton onClick={() => handleOpenAttachmentListModal(row)}>
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          )}
        <Tooltip title="Detalhes">
                <IconButton
                  onClick={() => handleOpenDetailDrawer(row)}
                  sx={{ ml: 1, padding: 0 }}
                >
                  <InfoOutlined fontSize="small" /> 
                </IconButton>
         </Tooltip>
          <ActionMenu
            row={row}
            handleOpenDescriptionModal={handleOpenDescriptionModal}
            handleOpenStatusModal={handleOpenStatusModal}
            handleOpenInputFileModal={handleOpenInputFileModal}
            handleOpenReviewModal={handleOpenReviewModal}
          />
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, marginLeft: 30 }}
          colSpan={13}
        >
          <Collapse
            in={expandedRow === index}
            timeout="auto"
            unmountOnExit
            sx={{ width: "60%" }}
          >
            <Box margin={1} marginLeft="40px">
              <Typography variant="body2">
                <strong>Descrição</strong>
              </Typography>
              <Typography variant="body1">{row.description || ""}</Typography>
              <Divider />
              <Typography variant="body2" marginTop={1}>
                <strong>Motivo que gerou a devolução</strong>
              </Typography>
              <Typography variant="body1">{row.claim?.reason || ""}</Typography>
             </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReturnRow;
