import {
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import PropTypes from "prop-types";
import { useMemo, forwardRef } from "react";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { useMobileMessage } from "src/contexts/mobile-message-context";
import { paths } from "src/paths";
import { AccountButton } from "../account-button";
import { NotificationsButton } from "../notifications-button";
import { TopNavSection } from "./top-nav-section";
import { useFilters } from "src/contexts/filters-context";
import { HelperDropdown } from "./HelperDropdown/HelperDropdown";
import { usePathname } from "src/hooks/use-pathname";
import { FreshworksWidgetProvider } from "src/contexts/fresh-works-widget-context";
import { Campaign } from "@mui/icons-material";
import { AnchorLink } from "./HelperDropdown/helperDropdown.styles";
import { useNotificationCounter } from "src/contexts/notification-counter-context";
import { api } from "src/services/api";
import { useEffect } from "react";
import { useNotification } from "src/hooks/use-notification";
import { useSocket } from "src/contexts/socket-context";
import { ThemeButton } from "../theme-button";
import { useChatKey } from "src/contexts/chat-key-context";

const useCssVars = (color) => {
  const theme = useTheme();
  return useMemo(() => {
    switch (color) {
      case "blend-in":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-divider-color": theme.palette.neutral[800],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-divider-color": theme.palette.divider,
            "--nav-border-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "discreet":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[900],
            "--nav-color": theme.palette.neutral[100],
            "--nav-divider-color": theme.palette.neutral[800],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[50],
            "--nav-color": theme.palette.text.primary,
            "--nav-divider-color": theme.palette.neutral[200],
            "--nav-border-color": theme.palette.divider,
            "--nav-logo-border": theme.palette.neutral[200],
            "--nav-section-title-color": theme.palette.neutral[500],
            "--nav-item-color": theme.palette.neutral[500],
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-divider-color": theme.palette.neutral[700],
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-divider-color": theme.palette.neutral[700],
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const TopNav = forwardRef((props, ref) => {
  const { color = "evident", onMobileNav, sections = [] } = props;
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));
  const cssVars = useCssVars(color);
  const theme = useTheme();
  const socket = useSocket();
  const { setMobileMessage } = useMobileMessage();
  const { reset } = useFilters();
  const pathname = usePathname();
  const HELPER_BASE_URL =
    "https://ajuda.weesu.com.br/support/solutions/151000218632";
  const { setNotificationCounter, notificationsTotalCount } = useNotificationCounter();
  const { setChatKey } = useChatKey();

  useEffect(() => {
    const controller = new AbortController();
    if (socket) {

      const handleEvent = async ({ ids, message_id, is_answered, blockNotification }) => {
        try {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const inner_chat_key = urlParams.get("key");

          const response = await api.get("/v1/conversation/counters", { signal: controller.signal });
          const { unanswered } = response.data;
          
          let showNotification = false;

          if (!ids.includes(inner_chat_key) || document.hidden) {
            setNotificationCounter({ ...unanswered });
            showNotification = true;
          } else {
            setNotificationCounter((prevState) => ({
              ...prevState,
              ...unanswered,
            }));
          }

          document.title = `${
            notificationsTotalCount() > 0 && showNotification ? `(${notificationsTotalCount()}) ` : ""
          }Weello`;

          if (document.hidden && message_id && !is_answered && !blockNotification) {
            await useNotification(message_id);
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error(error);
          }
        }
      }

      socket.on(`tenant_id-${window.localStorage.getItem("tenant_id")}`, handleEvent);

      return () => {
        controller.abort();
        socket.off(`tenant_id-${window.localStorage.getItem("tenant_id")}`, handleEvent);
      };
    }
  }, [socket]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const response = await api.get("/v1/conversation/counters", { signal: controller.signal });
        const { notifications, unanswered } = response.data;
        setNotificationCounter({ notifications, ...unanswered });
  
        document.title = `${notifications > 0 ? `(${notifications}) ` : ""}Weello`;
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      controller.abort();
    };
  }, []);

  const handleLogoClick = () => {
    reset();
    setMobileMessage(false);
    setChatKey('');
  };

  return (
    <Box
      ref={ref}
      component="header"
      sx={{
        ...cssVars,
        backgroundColor: "var(--nav-bg)",
        borderBottomColor: "var(--nav-border-color)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        color: "var(--nav-color)",
        left: 0,
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 1,
          [theme.breakpoints.down("desktop")]: {
            px: 1,
          },
        }}
      >
        <Stack direction="row">
          {!desktopUp && (
            <Stack
              display="flex"
              direction="row"
              alignItems="center"
              marginRight={1}
            >
              <Badge color="error" badgeContent={notificationsTotalCount() || 0}>
                <IconButton onClick={onMobileNav}>
                  <SvgIcon>
                    <Menu01Icon />
                  </SvgIcon>
                </IconButton>
              </Badge>
            </Stack>
          )}

          <Box
            component={RouterLink}
            href={paths.dashboard.questions}
            sx={{
              display: "inline-flex",
              p: "4px",
              width: 170,
              [theme.breakpoints.down("desktop")]: {
                width: 90,
                p: "0px",
              },
            }}
            onClick={handleLogoClick}
          >
            {!desktopUp ? (
              <img
                src="/assets/images/logo.png"
                alt="logo"
                style={{ width: "100%" }}
              />
            ) : (
              <Logo />
            )}
          </Box>
        </Stack>

        <Stack
          alignItems="center"
          direction="row"
          spacing={desktopUp ? 1 : 0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip title="Novidades Weello!">
              <AnchorLink href={`${HELPER_BASE_URL}`} target="_blank">
                <IconButton>
                  <Campaign sx={{ fontSize: "24px" }} />
                </IconButton>
              </AnchorLink>
            </Tooltip>
          </div>
          { desktopUp && 
            <>
              <HelperDropdown />
              <ThemeButton /> 
            </>
          }
          <NotificationsButton />
          <AccountButton />
        </Stack>
      </Stack>
      {desktopUp && (
        <Box
          sx={{
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: "var(--nav-divider-color)",
          }}
        >
          <Scrollbar
            sx={{
              "& .simplebar-scrollbar:before": {
                background: "var(--nav-scrollbar-color)",
              },
            }}
          >
            <Stack
              alignItems="center"
              component="nav"
              direction="row"
              spacing={1}
              sx={{
                px: 2,
                py: 1.5,
              }}
            >
              {sections.map((section, index) => (
                <TopNavSection
                  items={section.items}
                  key={index}
                  pathname={pathname}
                  subheader={section.subheader}
                />
              ))}
            </Stack>
          </Scrollbar>
        </Box>
      )}
    </Box>
  );
});

TopNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  onMobileNav: PropTypes.func,
  sections: PropTypes.array,
};
