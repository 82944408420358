import React from "react";
import {
  Box,
  Avatar,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Drawer,
  useMediaQuery,
  Divider,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Detail } from "./components/detail-return.js";
import TimeLine from "./components/time-line.js";
import { RightDrawer } from "src/components/right-drawer/index.js";
import { ReviewStatus } from "./components/review-status-component.js";

const DrawerDetail = ({
  open,
  onClose,
  details,
  shippingHistory,
  reviewStatus,
}) => {
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));
  
  return (
    <RightDrawer
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "8px 16px",
        }}
      >
        <IconButton color="inherit" onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: "16px" }}>
        <Detail details={details} />
        <Divider />
        <TimeLine shippingHistory={shippingHistory || []} />
        <Divider />
        <ReviewStatus reviewStatus={reviewStatus}/>
      </Box>
    </RightDrawer>
  );
};

export default DrawerDetail;
